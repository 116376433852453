import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import Aos from "aos";

import "aos/dist/aos.css";

const KnowMore = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    return(
        <section>
            <div className="know-more">
                <div className="img-txt" style ={ {backgroundImage: `url('../img/banner-home-strategic.jpg')` } }>
                    <h2>A strategic partner in the world's most important markets</h2>
                </div>
                <div className="contact-txt">
                    <article>
                        Want to learn more?
                    </article>
                    <Link data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" } to="/contact" className="btnM">
                        Contact us 
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default KnowMore