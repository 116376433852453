import React, { useLayoutEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";
import BannerHome from "./Banners/BannerHome";
import { useLocation } from "react-router-dom";
import Products from "./Products/products";
import AboutUs from "./AboutUs/AboutUs";
import KnowMore from "./KnowMore/KnowMore";

const Home = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div>
            <BannerHome />
            <Products />
            <AboutUs />
            <KnowMore />
        </div>
    );
}

export default Home;
