import React, { useState, useRef } from "react";
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm  } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import emailjs from '@emailjs/browser';

import swal from 'sweetalert'

const ContactoForm = () => {

    const form = useRef()

    const [contact, setContact] = useState({
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        mensaje: "",
        product: "",
        volumesrequired: "",
        onetimeorongoingorder: "",
        destinationport: "",
        targetprice: "",
    });

    const sendEmail = (e) => {
    
    emailjs.sendForm('gmail', 'mail-contacto-form', form.current, 'O_uPGJ9TtlPSRdW0L')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const createContact = () => {
        
        addDoc(collection(db, "contact"), {
            contact
        }).then(() => {
            swal(
                "THANKS FOR REACHING OUT", 
                `We will be responding to you shortly`, 
                "success");
            sendEmail()
            navigate('/')
        }).catch(error => {
            console.log(error)
            swal(
                "¡Oops!", 
                `Something failed`, 
                "error");
        })
    }
    
    return(
        <section>
            <div className="contacto">
                <div className="container-form">
                    <form ref={form} onSubmit={handleSubmit(createContact)}>
                        <div className="container-title">
                            <h3>Contact us to organize a call to discuss your needs</h3>
                        </div>
                        <div className="container-input">
                            <input 
                                type="text" 
                                name="nombre" 
                                placeholder="First name" 
                                value={contact.nombre}
                                {...register("nombre", { required: "This field is required" })}
                                onChange={(e) => setContact({...contact, nombre: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="nombre"
                                render={({ message }) => <p>{message}</p>}
                            />

                            <input 
                                type="text" 
                                name="apellido" 
                                placeholder="Last name" 
                                value={contact.apellido}
                                {...register('apellido', { required: "This field is required" })}
                                onChange={(e) => setContact({...contact, apellido: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="apellido"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="container-input">
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Email" 
                                value={contact.email}
                                {...register('email', { required: "This field is required" })}
                                onChange={(e) => setContact({...contact, email: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) => <p>{message}</p>}
                            />

                            <input 
                                type="number" 
                                name="telefono" 
                                placeholder="Phone number" 
                                pattern="[0-9]+" 
                                value={contact.telefono}
                                {...register('telefono', { required: "This field is required" })}
                                onChange={(e) => setContact({...contact, telefono: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="telefono"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="container-input">
                            <input 
                                type="text" 
                                name="product" 
                                placeholder="Product" 
                                value={contact.product}
                                {...register('product', { required: "This field is required" })}
                                onChange={(e) => setContact({...contact, product: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="product"
                                render={({ message }) => <p>{message}</p>}
                            />

                            <input 
                                type="text" 
                                name="volumesrequired" 
                                placeholder="Volumes required" 
                                value={contact.volumesrequired}
                                {...register('volumesrequired', { required: "This field is required" })}
                                onChange={(e) => setContact({...contact, volumesrequired: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="volumesrequired"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="container-input">
                            <input 
                                type="text" 
                                name="onetimeorongoingorder" 
                                placeholder="Is this a one-time or ongoing order" 
                                value={contact.onetimeorongoingorder}
                                {...register('onetimeorongoingorder', { required: "This field is required" })}
                                onChange={(e) => setContact({...contact, onetimeorongoingorder: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="onetimeorongoingorder"
                                render={({ message }) => <p>{message}</p>}
                            />

                            <input 
                                type="text" 
                                name="destinationport" 
                                placeholder="Destination port" 
                                value={contact.destinationport}
                                {...register('destinationport', { required: "This field is required" })}
                                onChange={(e) => setContact({...contact, destinationport: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="destinationport"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="container-input">
                            <input 
                                type="text" 
                                name="targetprice" 
                                placeholder="Target price" 
                                value={contact.targetprice}
                                {...register('targetprice', { required: "This field is required" })}
                                onChange={(e) => setContact({...contact, targetprice: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="targetprice"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="container-textarea">
                            <textarea 
                                type="text" 
                                name="mensaje" 
                                rows="6" 
                                placeholder="Message" 
                                value={contact.message}
                                {...register('mensaje')}
                                onChange={(e) => setContact({...contact, mensaje: e.target.value})}
                            />
                        </div>
                        
                        { !contact
                            ? ""
                            : <input type="submit" value="SEND" className="btnM" onClick={()=> {}}/>
                        }
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ContactoForm