import React, { useLayoutEffect } from "react";

import ContactoForm from "../ContactoForm/ContactoForm";
import { LinkMail, LinkInstagram, LinkWhatsApp } from '../Assets/Variables';

const Contacto = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return(
        <section>
            <div className="banner-section" style ={ {backgroundImage: `url('../img/banner-contact.jpg')` } }>
                <div className="container-banner-section">
                    <article>
                        SAF helps global producers
                        and brands expand into global
                        retail markets
                    </article>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                </div>
            </div>

            <div className="about-us-h"  style ={ {backgroundImage: "url('./img/bkg.png')" } }>
                <ContactoForm />
            </div>
            
        </section>
    )
}

export default Contacto