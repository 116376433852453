import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import Aos from "aos";

import "aos/dist/aos.css";

const AboutUs = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    return(
        <section>
            <div className="about-us-h"  style ={ {backgroundImage: "url('./img/bkg.png')" } }>
                <div className="intro">
                    <article>
                        SAF specializes in working with global production leaders of high quality, sustainably-sourced products in the development of their brands navigating market penetration into strategic global retail markets, either under a brand or through private label opportunities.
                    </article>
                </div>
                <div className="txt">
                    <article data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" }>
                        <span>A global platform that specializes in the development of strategic partnerships. </span>                                                                                                                                          
                        <br/>
                        SAF structures high value alliances between leading global producers with secure market positions, leaders in their categories, and final, end consumer distribution channels, that need to secure long term, high volume buying contracts.
                        <br/><br/><br/>
                        <Link to="/about-us" className="btnM">Learn more</Link>
                    </article>
                    <div className="separator-container">
                        <div className="separator1"></div>
                        <div className="separator2"></div>
                    </div>
                    <article data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" }>
                        <h2>OUR MISSION</h2>
                        To simplify the process of acquiring and distributing goods and raw materials, offering personalized solutions tailored to the specific needs of each client, with a focus on cost optimization and efficiency maximization.
                    </article>
                    <div className="separator-container">
                        <div className="separator1"></div>
                        <div className="separator2"></div>
                    </div>
                    <article data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" }>
                        <h2>OUR VISION</h2>
                        To provide a comprehensive vision of supply solutions ranging from top quality goods and raw materials to timely and secure delivery at every location.</article>
                </div>
                <div className="separator"></div>
            </div>
        </section>
    )
}

export default AboutUs