import React, { useState, useRef } from "react";
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm  } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import emailjs from '@emailjs/browser';

import swal from 'sweetalert'

const ContactoFormFooter = () => {

    const form = useRef()

    const [contact, setContact] = useState({
        nombre: "",
        email: "",
        mensaje: ""
    });

    const sendEmail = (e) => {
    
    emailjs.sendForm('gmail', 'mail-contacto', form.current, 'O_uPGJ9TtlPSRdW0L')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const createContact = () => {
        
        addDoc(collection(db, "contact"), {
            contact
        }).then(() => {
            swal(
                "THANKS FOR REACHING OUT", 
                `We will be responding to you shortly`, 
                "success");
            sendEmail()
            navigate('/')
        }).catch(error => {
            console.log(error)
            swal(
                "¡Oops!", 
                `Something failed`, 
                "error");
        })
    }
    
    return(
            <div className="contacto-footer">
                <div className="container-form">
                    <h3>CONTACT US</h3>
                    <form ref={form} onSubmit={handleSubmit(createContact)}>
                        <input 
                            type="text" 
                            name="nombre" 
                            placeholder="Name" 
                            value={contact.nombre}
                            {...register("nombre", { required: "This field is required" })}
                            onChange={(e) => setContact({...contact, nombre: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="nombre"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="email" 
                            name="email" 
                            placeholder="Email" 
                            value={contact.email}
                            {...register('email', { required: "This field is required" })}
                            onChange={(e) => setContact({...contact, email: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => <p>{message}</p>}
                        />
                        
                        <textarea 
                            type="text" 
                            name="mensaje" 
                            rows="6" 
                            placeholder="Message" 
                            value={contact.message}
                            {...register('mensaje')}
                            onChange={(e) => setContact({...contact, mensaje: e.target.value})}
                        />
                        
                        { !contact
                            ? ""
                            : <input type="submit" value="Send" className="btnM" onClick={()=> {}}/>
                        }
                    </form>
                </div>
            </div>
    )
}

export default ContactoFormFooter