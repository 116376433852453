import React, { useLayoutEffect, useEffect } from "react";
import Aos from "aos";

import "aos/dist/aos.css";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

const AboutUs = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    return (
        <section className="about-us">
            <div className="banner-section" style ={ {backgroundImage: `url('../img/banner-about-us.jpg')` } }>
                <div className="container-banner-section">
                    <article>
                        Commited to the
                        development of <span>sustaintable</span>, strategic
                        partnerships
                    </article>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                </div>
            </div>

            <div className="container-info">
                <div className="container-item">
                    <div className="img order1" style ={ {backgroundImage: `url('../img/about-us-01.jpg')` } }></div>
                    <div className="item order2">
                        <h2>
                            A global platform that specializes in the development of long-term strategic partnerships.
                        </h2>
                        <div className="blue-spacer">
                            <div className="uno"></div>
                            <div className="dos"></div>
                        </div>
                        <article>
                            SAF is a company based on integrity, with a solid and ever-evolving trajectory in the export of quality food products from South America to select global markets. With over two decades of accumulated experience, our history dates back to a fortuitous encounter where our founders, from diverse cultural and professional backgrounds, envisioned a unique opportunity.
                            <br/><br/>
                            The partnership between an American visionary with extensive international experience, living and working in Japan, China and Argentina, and an Argentine entrepreneur and agricultural and ranching expert, whose business expertise has been honed through practice and adaptability across various countries, laid the foundation for what SAF is today.
                            <br/><br/>
                            Since then, we have focused our efforts on establishing strong relationships with leading regional producers and exporters, emphasizing quality, traceability, and professionalism at every step of the process.
                            <br/><br/>
                            Our leaders’ professional background is noteworthy: extensive banking and financial experience, strong focus on sales, and a network of contacts in the American and Asian markets, combined with the experience and ability to establish and lead companies in diverse environments.
                            <br/><br/>
                            We actively participate in international events and exhibitions, allowing our partners to stay updated on market needs and emerging trends. Additionally, our vast experience in key markets, such as in Asia, the United States and South America, has enabled us to build business relationships based on trust and professionalism.
                            <br/><br/>
                            At SAF, we recognize the value of personal relationships in an increasingly virtual world. This conviction has been a cornerstone of our success, ensuring that each client and business partner is treated with the attention and respect they deserve. Ultimately, our company stands out for its unwavering commitment to quality, reliability, and excellence in every aspect of our international business.
                        </article>
                    </div>
                </div>

                <div className="blue-line"></div>

                <div className="container-item">
                    <div className={ window.innerWidth >= 1024 ? "img order2" : "img order1" } style ={ {backgroundImage: `url('../img/about-us-02.jpg')` } }></div>
                    <div className="item order1">
                        <article>
                            <p>Experts in Asia</p>
                            At SAF, we have extensive experience in Asia, specifically China and Japan. Our team has extensively worked in these markets, understanding their peculiarities and dynamics. We offer you the knowledge and strategies necessary to enter and thrive in these highly competitive markets.
                        </article>
                        <div className="blue-spacer">
                            <div className="uno"></div>
                            <div className="dos"></div>
                        </div>
                        <article>
                            <p>Experts in South America</p>
                            Our partners know South America as well as anyone. We have worked extensively in most of the South American markets, as well as throughout Central America and the Caribbean, and we know how to effectively navigate these markets. We are based in Argentina and are continually active in Brazil, Uruguay, and Chile.                        </article>
                        <div className="blue-spacer">
                            <div className="uno"></div>
                            <div className="dos"></div>
                        </div>
                        <article>
                            <p>Experts in North America</p>
                            In addition to our experience in Asia, we also have a strong track record in the US market. We have helped multiple suppliers establish and grow in the United States, a key market with its own demands and regulations. 
                            <br/><br/>
                            Our global distribution network is one of our greatest strengths. At SAF, we offer efficient and reliable logistics services that ensure your products reach the right markets at the right time. We have the operational infrastructure and experience to handle large volumes of products, ensuring a smooth and effective supply chain. 
                            <br/><br/>
                            SAF is your ideal partner. We can help you reach new markets, secure exclusive agreements, and our final objective is to successfully grow together.                        
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;
