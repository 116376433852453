import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAp6zSBVtfsUvKZbLz3JH9jLXp_Gk93d48",
  authDomain: "saf-web-d5229.firebaseapp.com",
  projectId: "saf-web-d5229",
  storageBucket: "saf-web-d5229.appspot.com",
  messagingSenderId: "601840660454",
  appId: "1:601840660454:web:e06faa0a3ff672ca81f027",
  measurementId: "G-98PN295B6F"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics();

export const db = getFirestore(app);

