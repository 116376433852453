import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import Aos from "aos";

import "aos/dist/aos.css";

const Products = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    return(
        <section>
            <div className="products-h">
                <div className="product-box" style ={ {backgroundImage: `url('../img/h-beef.jpg')` } }>
                    <h2 data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } >BEEF</h2>
                    <Link data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" }  to="/products"  className="btnO">Learn more</Link>
                </div>
                <div className="product-box" style ={ {backgroundImage: `url('../img/h-food.jpg')` } }>
                    <h2 data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } >FOOD</h2>
                    <Link  data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" } to="/products" className="btnO">Learn more</Link>
                </div>
                <div className="product-box" style ={ {backgroundImage: `url('../img/h-dairy.jpg')` } }>
                    <h2 data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } >DAIRY</h2>
                    <Link data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" } to="/products" className="btnO">Learn more</Link>
                </div>
                <div className="product-box" style ={ {backgroundImage: `url('../img/h-feed.jpg')` } }>
                    <h2 data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } >FEED</h2>
                    <Link  data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" } to="/products" className="btnO">Learn more</Link>
                </div>
                <div className="product-box" style ={ {backgroundImage: `url('../img/h-pet.jpg')` } }>
                    <h2 data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } >PET</h2>
                    <Link data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" } to="/products" className="btnO">Learn more</Link>
                </div>
            </div>
            <div className="separator"></div>
        </section>
    )
}

export default Products