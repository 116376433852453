import React from "react";

import { Link } from 'react-router-dom';

const NotA404 = () => {
    return (
        <section className='notA404'>
            <Link to="/">
                <div>
                    <img src="../../img/logoB.png" alt="logo"/>
                </div>
            </Link>
            <h4>
                OOPS! SOMETHING WENT WRONG
            </h4>
            <Link to="/" className="btnM">
                GO BACK
            </Link>
        </section>
    )
}

export default NotA404