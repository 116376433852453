import React, { useLayoutEffect, useEffect } from "react";
import Aos from "aos";

import "aos/dist/aos.css";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

const Solutions = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    return (
        <section className="solutions">
            <div className="banner-section" style ={ {backgroundImage: `url('../img/banner-solutions.jpg')` } }>
                <div className="container-banner-section">
                    <article>
                        SAF helps high quality producers
                        and brands expand into global
                        retail and strategic distribution channels
                    </article>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                </div>
            </div>

            <div className="intro-solutions">
                <div className="container-solutions">
                    <article>
                        <span>SAF carefully selects the finest Latin American suppliers with the primary goal of ensuring the highest quality in every product we offer. Our priority is to ensure that food comes from natural and sustainable production processes, respecting the most stringent standards of quality control and sustainability.</span>
                    </article>
                    <div className="separator-solutions"></div>
                    <article>
                        We are committed to working closely with our supplier partners to maintain the highest levels of quality at every stage. This involves not only selecting top-quality raw materials but also emphasizing sustainable production practices that respect the environment and promote the health and well-being of all involved.
                        <br/><br/>
                        Furthermore, we place special emphasis on ensuring quality during the logistics process, ensuring that our products reach our customers in optimal conditions, fresh, and ready to be enjoyed. Our commitment to quality encompasses every step of the way, and we are dedicated to providing our customers with the highest quality Latin American foods, produced responsibly, and delivered with the utmost care.
                    </article>
                </div>
            </div>

            <div className="container-card">
                <div className="card-solutions">
                    <div className="txt" data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-up" } >
                        SAF specializes in working with global production leaders of high quality, sustainably-sourced products in development of their brands and manage market penetration into strategic global retail markets, either under a Brand or through private label opportunities.
                    </div>
                    <div className="img" style ={ {backgroundImage: `url('../img/img-card-solutions.jpg')` } }></div>
                </div>
            </div>

            <div className="container-items">
                <div className="items-solutions">
                    <div className="items" data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-right" } >
                        <img src="../img/icon1.png" alt="Identification of Producers" title="Identification of Producers"/>
                        <h3>Identification of Producers:</h3>
                        <article>
                            Research and evaluation of producers that meet the quality and safety standards required to export to strategic global markets.
                        </article>
                    </div>

                    <div className="items" data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } >
                        <img src="../img/icon2.png" alt="Negotiation and Selection of Producers" title="Negotiation and Selection of Producers"/>
                        <h3>Negotiation and Selection of Producers:</h3>
                        <article>
                        Contact with preselected producers.
                        Negotiation of commercial terms, prices, volumes, and delivery conditions.
                        </article>
                    </div>

                    <div className="items" data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-right" } >
                        <img src="../img/icon3.png" alt="Establishment of Contracts" title="Establishment of Contracts"/>
                        <h3>Establishment of Contracts:</h3>
                        <article>
                            Formalization of contracts with selected producers, including supply agreements, quality standards, and payment terms.                        
                        </article>
                    </div>

                    <div className="items" data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } >
                        <img src="../img/icon4.png" alt="Inspection and certification of Products" title="Inspection and certification of Products"/>
                        <h3>Inspection and certification of Products:</h3>
                        <article>
                            Inspection of products to ensure they meet established quality and safety standards. Obtaining the necessary quality and safety certificates.
                        </article>
                    </div>

                    <div className="items" data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-right" } >
                        <img src="../img/icon5.png" alt="Storage and Logistics" title="Storage and Logistics"/>
                        <h3>Storage and Logistics:</h3>
                        <article>
                            Coordination of storage and transportation logistics for products from the producers' facilities to the export ports.
                        </article>
                    </div>

                    <div className="items" data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } >
                        <img  src="../img/icon6.png" alt="Export Process" title="Export Process"/>
                        <h3>Export Process:</h3>
                        <article>
                            Preparation of the documentation required for export, including commercial invoices, customs declarations, and phytosanitary certificates. Coordination with freight forwarders and customs brokers to ensure timely shipment of the products.
                        </article>
                    </div>

                    <div className="items" data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-right" } >
                        <img src="../img/icon7.png" alt="Transport and Shipment" title="Transport and Shipment"/>
                        <h3>Transport and Shipment:</h3>
                        <article>
                            Organization of maritime or air transportation of products from export ports to entry ports in strategic global markets.
                        </article>
                    </div>

                    <div className="items"  data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } >
                        <img src="../img/icon8.png" alt="Import Process in the United States" title="Import Process in the United States"/>
                        <h3>Manage imports into China, US, etc.:</h3>
                        <article>
                            Compliance with product import requirements established by global customs and food safety authorities.
                            Customs clearance.
                        </article>
                    </div>

                    <div className="container-map">
                        <div className="img">
                            <img src="../img/solutions-map.png" />
                        </div>
                        <div className="separator-container">
                            <div className="separator1"></div>
                            <div className="separator2"></div>
                        </div>
                        <article>
                            <h2>Strategic Partnerships</h2>
                            With offices and strategic affiliates on numerous continents, SAF strives to add value to companies and organizations that are interested in developing long-term business relationships. Today, SAF has on-the-ground partners throughout Latin America (in Argentina, Brazil, Chile and Uruguay), the US, and in Asia (in China, Japan, and Thailand).
                            <br/><br/>
                            The SAF team and its partners have built strong relationships and understand the value of establishing professional relationships with globally-minded, strategic partners. 
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Solutions;
