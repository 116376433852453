import React, { useLayoutEffect, useEffect } from "react";
import Aos from "aos";

import "aos/dist/aos.css";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";
import ContactoForm from "../ContactoForm/ContactoForm";

const Products = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    return (
        <section className="products">
            <div className="banner-section" style ={ {backgroundImage: `url('../img/banner-products.jpg')` } }>
                <div className="container-banner-section">
                    <article>
                        <span>SAF helps to tell the stories of these unique products</span> and connect you with the right buyers
                    </article>
                    <p>
                        We promote hard-to-find products, the best
                        of the best, that are all natural and sustainably sourced.
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                </div>
            </div>

            <div className="intro">
                <article>
                    SAF specializes in the highest quality, sustainably-sourced all-natural and healthy products.
                </article>
            </div>

            <div className="container-products">
                <div className="product" style ={ {backgroundImage: `url('../img/prod01.jpg')` } }>
                    <article>
                        The highest quality, premium Argentine, certified-Angus beef into top distribution channels China.
                    </article>
                    <div className="container-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </div>
                </div>

                <div className="product" style ={ {backgroundImage: `url('../img/prod02.jpg')` } }>
                    <article>
                        Wild caught, Argentine red shrimp, chemical-free into top tier retailers in the US.
                    </article>
                    <div className="container-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </div>
                </div>  

                <div className="product" style ={ {backgroundImage: `url('../img/prod03.jpg')` } }>
                    <article>
                        Work with the leading producer of Acai (from the depths of the Brazilian Amazon) into select channels in the US.
                    </article>
                    <div className="container-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </div>
                </div>  

                <div className="product" style ={ {backgroundImage: `url('../img/prod04.jpg')` } }>
                    <article>
                        Partnered with the #1 producer of organic apples focusing on distribution into select US and global retail channels.
                    </article>
                    <div className="container-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </div>
                </div>  

                <div className="product" style ={ {backgroundImage: `url('../img/prod05.jpg')` } }>
                    <article>
                        Bringing CPP, an all-natural, high-fiber pellet from leading orange juice producer in Brazil into select global dairies.
                    </article>
                    <div className="container-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </div>
                </div>      
                
                <div className="product" style ={ {backgroundImage: `url('../img/prod06.jpg')` } }>
                    <article>
                        Work with leading seafood producer in Argentina in the development of a wild caught Hake fish meal with 60% protein in the distribution in select pet food and aquaculture channels internationally.
                    </article>
                    <div className="container-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </div>
                </div>  

                <div className="product" style ={ {backgroundImage: `url('../img/prod07.jpg')` } }>
                    <article>
                        Developed high-end, premium premium treats and food products for the pet industry in the US.
                    </article>
                    <div className="container-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </div>
                </div>        
            </div>

                <article>
                    Be it helping a global buyer secure long-term supply contracts, or helping a South American producer grow sales internationally, SAF can help.
                    <br/><br/>
                    With vast experience and deep knowledge of executing business strategies internationally, SAF is a reliable partner for sourcing and selecting strategic producers that meet your product requirements. Our robust network of contacts and commitment to excellence enable us to identify the best production partners that meet your specific needs.
                    <br/><br/>
                    We understand that each client has unique requirements and particular demands. Therefore, we invite you to share your special needs with us. Whether you're looking for gourmet ingredients, organic products, or any other specific item, we are here to carefully analyze each case.
                    <br/><br/>
                    Our highly trained team will meticulously study your request and evaluate all available options in the market. No matter how unique your need is, we are prepared to analyze each case and provide detailed information about the available possibilities.
                    <br/><br/>
                    At SAF, we are committed to delivering exceptional service and working closely with you to find the best possible solutions.
                </article>

                <ContactoForm />
        </section>
    );
}

export default Products;
