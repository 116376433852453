import React from "react";
import { Link } from 'react-router-dom';

const BannerHome = () => {

    return(
        <section>
            <div className="banner-home" style ={ {backgroundImage: `url('../img/banner-home.jpg')` } }>
                <div className="container">
                    <article>
                        <div className="main-bh">
                            A global platform
                            that specializes in
                            the development
                            of strategic
                            partnerships
                        </div>
                    </article>
                    <Link to="/solutions" className="btnM">Get to know us</Link>
                    <article>
                        <div className="comp-bh">
                            Focusing on niche premium products that are healthy,
                            nutritional and sustainably-produced.
                        </div>
                    </article>
                </div>
            </div>
        </section>
    )
}

export default BannerHome