import React from "react";
import { Link } from 'react-router-dom';
import ContactoFormFooter from "../ContactoFormFooter/ContactoFormFooter";

const Footer = () => {
    return (
        <footer>
            <div className="container-footer">
                <div className="container-locations">
                    <Link to="/" >
                        <img src="../../img/logoB.png" alt="MOMO" className="logo"/>
                    </Link> 
                    <div>
                        <article>
                            AUSTIN
                        </article>
                        <article>
                            MIAMI
                        </article>
                        <article>
                            BUENOS AIRES
                        </article>
                        <article>
                            HONG KONG
                        </article>
                        <article>
                            SHANGHAI
                        </article>
                        <article className="nolines">
                            SAO PAULO
                        </article>
                    </div>
                </div>
                <ContactoFormFooter />
            </div>
            <div className="legal">
                Copyright 2024 © SAF Global. All rights reserved.
            </div>
        </footer>
    )
}

export default Footer